::selection {
	background: rgba(39, 119, 150, 1) !important;
	color: #fff;
}

body,
html {
	margin: 0;
	height: 100%;
}
.example {
	text-align: center;
	display: flex;
	flex: 1;
	align-content: center;
	flex-direction: column;
	justify-content: center;
	height: 100%;

	border-radius: 4px;
	margin-bottom: 20px;
	padding: 30px 50px;
	margin: 20px 0;

	h2 {
		color: #8492a2;
		-moz-animation: showHide 4.5s ease-in alternate infinite; /* Firefox */
		-webkit-animation: showHide 4.5s ease-in alternate infinite; /* Safari and Chrome */
		-ms-animation: showHide 4.5s ease-in alternate infinite; /* IE10 */
		-o-animation: showHide 4.5s ease-in alternate infinite; /* Opera */
		animation: showHide 4.5s ease-in alternate infinite;
	}

	h1 {
		color: #8492a2;
	}
}

.ant-select-selection {
	border: none !important;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
}

@-webkit-keyframes showHide {
	/* Chrome, Safari */
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes showHide {
	/* FF */
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-ms-keyframes showHide {
	/* IE10 */
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-o-keyframes showHide {
	/* Opera */
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes showHide {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.gabys__button {
	background: #fff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	border: 1px solid #f7f7f7;
	padding: 10px 10px;
	transition: .3s;
}

.gabbys__button:hover {
	cursor: pointer;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, .3);
	background: #f7f7f7;
}

.ant-table-thead > tr > th {
	background: white !important;
}

th {
	background: transparent !important;
}
.stat__rating {
	&--green {
		color: #2fd048;
	}
	&--yellow {
		color: #febf01;
	}
	&--red {
		color: #f13e0e;
	}
	&--orange {
		color: #fa9705;
	}
}
input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

input::selection,
select::selection,
textarea::selection,
button::selection {
	outline: none;
}

::selection {
	border-color: rgba(39, 119, 150, 1) !important;
}

.ant {
	&-input::-moz-placeholder {
		color: #bfbfbf;
		opacity: 1;
	}
	&-input:-ms-input-placeholder {
		color: #bfbfbf;
	}
	&-input::-webkit-input-placeholder {
		color: #bfbfbf;
	}
	&-input:hover {
		border-color: rgba(39, 119, 150, 0.5) !important;
		border-right-width: 1px !important;
	}
	&-input:focus {
		border-color: rgba(39, 119, 150, 0.35) !important;
		outline: 0;
		-webkit-box-shadow: 0 0 0 2px rgba(39, 119, 150, 0.2) !important;
		box-shadow: 0 0 0 2px rgba(39, 119, 150, 0.2) !important;
		border-right-width: 1px !important;
	}
	&-input-disabled {
		background-color: #f5f5f5;
		opacity: 1;
		cursor: not-allowed;
		color: rgba(0, 0, 0, 0.25);
	}
	&-input-disabled:hover {
		border-color: #e6d8d8;
		border-right-width: 1px !important;
	}
}

.input {
	&__style {
		&--1 {
			background: $porcelain;
			border: 0.05rem solid rgba(0, 0, 0, 0.05);
		}
		&--1::selection {
			background: $porcelain;
			border: none;
		}
	}
}

.button {
	&__style {
		&--1 {
			background: rgba(39, 119, 150, 1) !important;
			border: none !important;
			color: rgba(255, 255, 255, 1) !important;
			margin-right: 2rem;
			padding: 0.5rem 2rem;
			border-radius: 2px !important;
			box-shadow: 0 6px 13px rgba(0, 0, 0, 0.35);
			transition: all 1.25s;
		}
		&--login {
			background: rgba(39, 119, 150, 1) !important;
			border: none !important;
			color: rgba(255, 255, 255, 1) !important;
			font-size: 1.5rem;
			font-weight: bold;
			border-radius: 2px !important;
			box-shadow: 0 6px 13px rgba(0, 0, 0, 0.35);
			transition: all 1.25s;
		}
		&--disabled {
			background: rgba(39, 119, 150, 0.4) !important;
			border: none !important;
			color: rgba(255, 255, 255, 0.6) !important;
			font-size: 1.5rem;
			font-weight: bold;
			border-radius: 2px !important;
			box-shadow: 0 6px 13px rgba(0, 0, 0, 0.15);
			transition: all 1.25s;
		}
	}
}

.results-container .ant-table-row-expand-icon {
	padding: 0rem;
}

.footer {
	height: 60px;
	width: 100%;
	bottom: 0px;
	left: 0px;
	right: 0px;
	margin-bottom: 0px;
	background: #126c71;
	&__copyright {
		font-size: 1.25rem;
	}
}

.workbooks {
	&__item {
		&:hover {
			border-right-color: #32b9ff;
			border-right-width: 2px;
			border-right-style: solid;
			animation: buildBorder 1s;
		}
		&:focus {
			border-right-color: #32b9ff;
			border-right-width: 2px;
			border-right-style: solid;
		}
	}
}

@keyframes buildBorder {
	from {
		border-right-color: transparent;
		border-right-width: 0px;
		border-right-style: solid;
	}
	to {
		border-right-color: #32b9ff;
		border-right-width: 2px;
		border-right-style: solid;
	}
}

.icon {
	float: right;
	margin: 0rem 1rem 0rem 1rem;
	&__twitter {
		font-size: 18px;
		color: #1da1f2;
		transition: all 0.5s;
		&--disabled {
			font-size: 18px;
			color: #565759;
		}
	}
	&__twitter:hover {
		transform: scale(1.2);
	}
	&__facebook {
		font-size: 18px;
		color: #3b5998;
		transition: all 0.5s;
		&--disabled {
			font-size: 18px;
			color: #565759;
		}
	}
	&__facebook:hover {
		transform: scale(1.2);
	}
	&__linkedin {
		&--disabled {
			font-size: 18px;
			color: #565759;
			transition: all 0.5s;
		}
		font-size: 18px;
		color: #0077b5;
		transition: all 0.5s;
	}
	&__linkedin:hover {
		transform: scale(1.2);
	}
	&__github {
		font-size: 18px;
		color: #6e5494;
		transition: all 0.5s;
		&--disabled {
			font-size: 18px;
			color: #565759;
		}
	}
	&__github:hover {
		transform: scale(1.2);
	}
	&__instagram {
		margin-top: 0.25rem;
		background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
		color: white;
		padding: 2px;
		border-radius: 5px;
		&--disabled {
			font-size: 18px;
			color: #565759;
		}
	}
	&__instagram:hover {
		transform: scale(1.2);
	}
}
